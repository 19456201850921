import { useTranslation } from 'gatsby-plugin-react-i18next'
import React, { useCallback, useState } from 'react'

import coFounderImg from '../../assets/co-founder.png'
import coFounderImg2x from '../../assets/co-founder@2x.png'
import ArrowDownIcon from '../../assets/icons/arrow-down.svg'
import Signature from '../../assets/signature-pp.svg'

const SectionWhy = () => {
  const { t } = useTranslation('components', { keyPrefix: 'sectionWhy' })
  const [isMoreVisible, setMoreVisible] = useState(false)
  const handleToggleMore = useCallback(
    () => setMoreVisible(value => !value),
    [setMoreVisible],
  )

  return (
    <section className="bg-gray-gradient">
      <div className="container mx-auto">
        <div className="grid grid-cols-12 gap-y-14 sm:gap-y-0 py-14 lg:py-24">
          <div className="col-span-12 sm:col-span-5 md:col-span-4 lg:col-span-3">
            <div className="grid grid-cols-2 sm:grid-cols-1 items-center">
              <img
                className="mb-7"
                src={coFounderImg}
                srcSet={`${coFounderImg} 1x, ${coFounderImg2x} 2x`}
                alt="Co-Founder"
              />
              <div className="flex flex-col sm:hidden">
                <span className="text-2xl">{t('owner.name')}</span>
                <span className="leading-6 uppercase">
                  {t('owner.position')}
                </span>
                <Signature className="mt-5" />
              </div>
              <span className="hidden sm:block text-2xl">
                {t('owner.name')}
              </span>
              <span className="hidden sm:block leading-6 uppercase">
                {t('owner.position')}
              </span>
              <Signature className="hidden sm:block mt-5" />
            </div>
          </div>
          <div className="col-span-12 sm:col-span-7 md:col-span-8 lg:col-span-9 order-first sm:order-none">
            <h4 className="mb-3 text-2xl text-blue uppercase">{t('title')}</h4>
            <div className="xl:mr-24 lg:text-lg">
              <p>{t('text1')}</p>
              <p>{t('text2')}</p>
              {isMoreVisible && <p>{t('text3')}</p>}
            </div>
            <button
              className="flex items-center text-lg font-bold"
              onClick={handleToggleMore}
              type="button"
            >
              <span>
                <ArrowDownIcon
                  className={`transition duration-500 easy-in-out ${
                    isMoreVisible ? 'rotate-180' : 'rotate-0'
                  }`}
                />
              </span>
              <span className="pl-2">
                {isMoreVisible ? t('less') : t('more')}
              </span>
            </button>
          </div>
        </div>
      </div>
    </section>
  )
}

export { SectionWhy }
