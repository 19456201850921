import { useTranslation } from 'gatsby-plugin-react-i18next'
import React from 'react'

import { FlagisDemo } from '../flagis-demo'

const SectionDemoITSpecialist = () => {
  const { t } = useTranslation('components', {
    keyPrefix: 'sectionDemo.data.it',
  })

  const tags = {
    chatbot: { colorIndex: 13, value: t('tags.chatbot') },
    deployment: { colorIndex: 22, value: t('tags.deployment') },
    eshop: { colorIndex: 5, value: t('tags.eshop') },
    inProgress: { colorIndex: 9, value: t('tags.inProgress') },
    learn: { colorIndex: 12, value: t('tags.learn') },
    projects: { colorIndex: 18, value: t('tags.projects') },
    todo: { colorIndex: 3, value: t('tags.todo') },
    movies: { colorIndex: 14, value: t('tags.movies') },
    music: { colorIndex: 16, value: t('tags.music') },
  }

  return (
    <FlagisDemo
      filters={[
        {
          type: 'tag',
          ...tags.projects,
          children: [
            {
              type: 'tag',
              ...tags.eshop,
              children: [
                { type: 'tag', ...tags.todo },
                { type: 'tag', ...tags.inProgress },
                { type: 'tag', ...tags.deployment },
              ],
            },
            {
              type: 'tag',
              ...tags.chatbot,
              children: [
                { type: 'tag', ...tags.todo },
                { type: 'tag', ...tags.inProgress },
                { type: 'tag', ...tags.deployment },
              ],
            },
          ],
        },
        { type: 'tag', ...tags.todo },
        { type: 'tag', ...tags.learn },
        { type: 'tag', ...tags.movies },
        { type: 'tag', ...tags.music },
      ]}
      tasks={[
        {
          subject: t('tasks.1'),
          tags: [tags.projects, tags.todo],
        },
        {
          subject: t('tasks.2'),
          tags: [tags.projects, tags.eshop, tags.todo],
        },
        {
          subject: t('tasks.3'),
          tags: [tags.projects, tags.eshop, tags.inProgress],
        },
        {
          subject: t('tasks.4'),
          tags: [tags.projects, tags.eshop, tags.inProgress],
        },
        {
          subject: t('tasks.5'),
          tags: [tags.projects, tags.eshop, tags.deployment],
        },
        {
          subject: t('tasks.6'),
          tags: [tags.projects, tags.chatbot, tags.todo],
        },
        {
          subject: t('tasks.7'),
          tags: [tags.projects, tags.chatbot, tags.inProgress],
        },
        {
          subject: t('tasks.8'),
          tags: [tags.projects, tags.chatbot, tags.deployment],
        },
        { subject: t('tasks.9'), tags: [tags.learn] },
        { subject: t('tasks.10'), tags: [tags.movies] },
        { subject: t('tasks.11'), tags: [tags.music, tags.todo] },
      ]}
    />
  )
}

export { SectionDemoITSpecialist }
