import { useTranslation } from 'gatsby-plugin-react-i18next'
import React from 'react'

import { SectionDemoITSpecialist } from './section-demo-it-specialist'
import { SectionDemoListItem } from './section-demo-list-item'
import { SectionDemoManager } from './section-demo-manager'
import { SectionDemoMother } from './section-demo-mother'
import { SectionDemoStudent } from './section-demo-student'

import { useSwitcher } from '../common/use-switcher'

const SectionDemo = () => {
  const { t } = useTranslation('components', { keyPrefix: 'sectionDemo' })
  const { handleIsActive, handleSwitch } = useSwitcher()

  return (
    <section className="hidden lg:block mt-6" id="inspiration">
      <div className="container mx-auto">
        <h4 className="text-4xl font-bold text-center text-blue">
          {t('title')}
        </h4>
        <div className="flex justify-center mt-12 mb-10">
          <div className="grid grid-cols-4">
            <SectionDemoListItem
              isActive={handleIsActive(0)}
              onClick={handleSwitch(0)}
              value={t('items.manager')}
            />
            <SectionDemoListItem
              isActive={handleIsActive(1)}
              onClick={handleSwitch(1)}
              value={t('items.mother')}
            />
            <SectionDemoListItem
              isActive={handleIsActive(2)}
              onClick={handleSwitch(2)}
              value={t('items.it')}
            />
            <SectionDemoListItem
              isActive={handleIsActive(3)}
              onClick={handleSwitch(3)}
              value={t('items.student')}
            />
          </div>
        </div>
        <div>
          {handleIsActive(0) && <SectionDemoManager />}
          {handleIsActive(1) && <SectionDemoMother />}
          {handleIsActive(2) && <SectionDemoITSpecialist />}
          {handleIsActive(3) && <SectionDemoStudent />}
        </div>
      </div>
    </section>
  )
}

export { SectionDemo }
