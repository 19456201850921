import { useTranslation } from 'gatsby-plugin-react-i18next'
import React from 'react'

import evaImg from '../../assets/demo/users/eva.png'
import { FlagisDemo } from '../flagis-demo'

const SectionDemoStudent = () => {
  const { t } = useTranslation('components', {
    keyPrefix: 'sectionDemo.data.student',
  })

  const tags = {
    calls: { colorIndex: 7, value: t('tags.calls') },
    history: { colorIndex: 1, value: t('tags.history') },
    leisure: { colorIndex: 0, value: t('tags.leisure') },
    maths: { colorIndex: 17, value: t('tags.maths') },
    payments: { colorIndex: 5, value: t('tags.payments') },
    personal: { colorIndex: 2, value: t('tags.personal') },
    school: { colorIndex: 19, value: t('tags.school') },
    shopping: { colorIndex: 9, value: t('tags.shopping') },
    travel: { colorIndex: 11, value: t('tags.travel') },
    exhibitions: { colorIndex: 10, value: t('tags.exhibitions') },
    concerts: { colorIndex: 12, value: t('tags.concerts') },
  }

  const users = {
    eva: { image: evaImg, value: t('users.eva') },
  }

  return (
    <FlagisDemo
      filters={[
        { type: 'tag', ...tags.personal },
        {
          type: 'tag',
          ...tags.school,
          children: [
            { type: 'tag', ...tags.maths },
            { type: 'tag', ...tags.history },
          ],
        },
        {
          type: 'tag',
          ...tags.shopping,
          children: [
            { type: 'tag', ...tags.school },
            { type: 'tag', ...tags.personal },
          ],
        },
        {
          type: 'tag',
          ...tags.leisure,
          children: [
            { type: 'tag', ...tags.exhibitions },
            { type: 'tag', ...tags.concerts },
          ],
        },
        { type: 'tag', ...tags.travel },
        { type: 'tag', ...tags.calls },
        { type: 'tag', ...tags.payments },
      ]}
      tasks={[
        { subject: t('tasks.1'), tags: [tags.personal] },
        {
          subject: t('tasks.2'),
          tags: [tags.shopping, tags.personal],
        },
        {
          subject: t('tasks.3'),
          tags: [tags.school, tags.calls],
        },
        { subject: t('tasks.4'), tags: [tags.payments, tags.school] },
        {
          subject: t('tasks.5'),
          tags: [tags.school, tags.maths, tags.shopping],
        },
        { subject: t('tasks.6'), tags: [tags.school, tags.maths] },
        {
          subject: t('tasks.7'),
          tags: [tags.school, tags.history],
          user: users.eva,
        },
        { subject: t('tasks.8'), tags: [tags.leisure] },
        { subject: t('tasks.9'), tags: [tags.travel] },
        { subject: t('tasks.10'), tags: [tags.leisure, tags.exhibitions] },
        { subject: t('tasks.11'), tags: [tags.leisure, tags.concerts] },
      ]}
    />
  )
}

export { SectionDemoStudent }
