import { useTranslation } from 'gatsby-plugin-react-i18next'
import React from 'react'

import richardImg from '../../assets/demo/users/richard.png'
import { FlagisDemo } from '../flagis-demo'

const SectionDemoMother = () => {
  const { t } = useTranslation('components', {
    keyPrefix: 'sectionDemo.data.mother',
  })

  const tags = {
    calls: { colorIndex: 7, value: t('tags.calls') },
    children: { colorIndex: 18, value: t('tags.children') },
    clubs: { colorIndex: 9, value: t('tags.clubs') },
    doctor: { colorIndex: 22, value: t('tags.doctor') },
    household: { colorIndex: 8, value: t('tags.household') },
    meetings: { colorIndex: 1, value: t('tags.meetings') },
    payments: { colorIndex: 2, value: t('tags.payments') },
    school: { colorIndex: 5, value: t('tags.school') },
    shopping: { colorIndex: 12, value: t('tags.shopping') },
    recipes: { colorIndex: 4, value: t('tags.recipes') },
    car: { colorIndex: 20, value: t('tags.car') },
  }

  const users = {
    richard: { image: richardImg, value: t('users.richard') },
  }

  return (
    <FlagisDemo
      filters={[
        { type: 'tag', ...tags.household },
        { type: 'user', ...users.richard },
        {
          type: 'tag',
          ...tags.children,
          children: [
            { type: 'tag', ...tags.school },
            { type: 'tag', ...tags.clubs },
            { type: 'tag', ...tags.doctor },
          ],
        },
        { type: 'tag', ...tags.shopping },
        { type: 'tag', ...tags.payments },
        { type: 'tag', ...tags.calls },
        { type: 'tag', ...tags.meetings },
        { type: 'tag', ...tags.doctor },
        { type: 'tag', ...tags.recipes },
        { type: 'tag', ...tags.car },
      ]}
      tasks={[
        { subject: t('tasks.1'), tags: [tags.household] },
        {
          subject: t('tasks.2'),
          tags: [tags.children, tags.clubs, tags.shopping],
          user: users.richard,
        },
        {
          subject: t('tasks.3'),
          tags: [tags.children, tags.school, tags.payments],
        },
        {
          subject: t('tasks.4'),
          tags: [tags.children, tags.school, tags.meetings],
        },
        {
          subject: t('tasks.5'),
          tags: [tags.children, tags.clubs],
        },
        {
          subject: t('tasks.6'),
          tags: [tags.children, tags.doctor, tags.calls],
        },
        { subject: t('tasks.7'), tags: [tags.shopping] },
        { subject: t('tasks.8'), tags: [tags.calls] },
        { subject: t('tasks.9'), tags: [tags.meetings] },
        { subject: t('tasks.10'), tags: [tags.recipes] },
        { subject: t('tasks.11'), tags: [tags.car] },
      ]}
    />
  )
}

export { SectionDemoMother }
