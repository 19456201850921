const computeIntHash = (str, max) => {
  const chars = str.split('')
  const prime = 3
  let sum = 0
  for (let i = 0; i < chars.length; i++) {
    const code = chars[i].charCodeAt(0)
    sum += code * i * prime
  }

  return sum % max
}

export { computeIntHash }
