import { useTranslation } from 'gatsby-plugin-react-i18next'
import React from 'react'
import Lottie from 'react-lottie'

import ArrowRightIcon from '../../assets/icons/arrow-right.svg'
import Simplicity from '../../assets/icons/simplicity.svg'
import simplicityAnimationCs from '../../assets/lotties/cs/simplicity'
import simplicityAnimation from '../../assets/lotties/simplicity'
import { useCurrentLanguage } from '../../hooks/useCurrentLanguage'
import { Link } from '../common/link'
import {
  TwoColumn,
  TwoColumnAnimation,
  TwoColumnContent,
} from '../common/two-column'

const SectionSimplicity = () => {
  const { t } = useTranslation('components', { keyPrefix: 'sectionSimplicity' })
  const { isCs } = useCurrentLanguage()

  return (
    <section className="simplicity bg-blue-gradient pt-20 sm:pt-28 lg:pt-36 pb-14 lg:pb-24">
      <div className="container mx-auto text-white">
        <TwoColumn>
          <TwoColumnContent className="self-center">
            <div className="flex items-center lg:block space-x-8 lg:space-x-0 pb-4 lg:pb-0">
              <Simplicity className="w-auto" />
              <h2 className="inline-block lg:block lg:py-6 text-2xl font-bold uppercase">
                {t('title')}
              </h2>
            </div>
            <p className="text-lg lg:whitespace-pre-line">{t('text')}</p>
            <Link
              className="flex items-center pt-2 text-green"
              to={t('more.to')}
            >
              <ArrowRightIcon className="stroke-current" />
              <span className="ml-2.5 font-title text-lg font-bold leading-5">
                {t('more.value')}
              </span>
            </Link>
          </TwoColumnContent>
          <TwoColumnAnimation>
            <Lottie
              options={{
                animationData: isCs
                  ? simplicityAnimationCs
                  : simplicityAnimation,
              }}
            />
          </TwoColumnAnimation>
        </TwoColumn>
      </div>
    </section>
  )
}

export { SectionSimplicity }
