const TAG_COLOR = {
  0: '#F7BED2',
  1: '#F99C9C',
  2: '#D36363',
  3: '#C55B79',
  4: '#D88C69',
  5: '#E4B25F',
  6: '#F7CE4D',
  7: '#E4DB27',
  8: '#7BA6DE',
  9: '#52C2D9',
  10: '#4DCBBD',
  11: '#9EDB5B',
  12: '#5BDBA3',
  13: '#3FB198',
  14: '#B3AD36',
  15: '#C8C629',
  16: '#34A2EE',
  17: '#6999B9',
  18: '#9188E3',
  19: '#E197F0',
  20: '#F288C1',
  21: '#D28CB2',
  22: '#ADB09F',
  23: '#6F8083',
}

const TAG_COLOR_CSS_BG = {
  0: 'bg-[#F7BED2]',
  1: 'bg-[#F99C9C]',
  2: 'bg-[#D36363]',
  3: 'bg-[#C55B79]',
  4: 'bg-[#D88C69]',
  5: 'bg-[#E4B25F]',
  6: 'bg-[#F7CE4D]',
  7: 'bg-[#E4DB27]',
  8: 'bg-[#7BA6DE]',
  9: 'bg-[#52C2D9]',
  10: 'bg-[#4DCBBD]',
  11: 'bg-[#9EDB5B]',
  12: 'bg-[#5BDBA3]',
  13: 'bg-[#3FB198]',
  14: 'bg-[#B3AD36]',
  15: 'bg-[#C8C629]',
  16: 'bg-[#34A2EE]',
  17: 'bg-[#6999B9]',
  18: 'bg-[#9188E3]',
  19: 'bg-[#E197F0]',
  20: 'bg-[#F288C1]',
  21: 'bg-[#D28CB2]',
  22: 'bg-[#ADB09F]',
  23: 'bg-[#6F8083]',
}

const TAG_COLOR_CSS_TEXT = {
  0: 'text-[#F7BED2]',
  1: 'text-[#F99C9C]',
  2: 'text-[#D36363]',
  3: 'text-[#C55B79]',
  4: 'text-[#D88C69]',
  5: 'text-[#E4B25F]',
  6: 'text-[#F7CE4D]',
  7: 'text-[#E4DB27]',
  8: 'text-[#7BA6DE]',
  9: 'text-[#52C2D9]',
  10: 'text-[#4DCBBD]',
  11: 'text-[#9EDB5B]',
  12: 'text-[#5BDBA3]',
  13: 'text-[#3FB198]',
  14: 'text-[#B3AD36]',
  15: 'text-[#C8C629]',
  16: 'text-[#34A2EE]',
  17: 'text-[#6999B9]',
  18: 'text-[#9188E3]',
  19: 'text-[#E197F0]',
  20: 'text-[#F288C1]',
  21: 'text-[#D28CB2]',
  22: 'text-[#ADB09F]',
  23: 'text-[#6F8083]',
}

export { TAG_COLOR, TAG_COLOR_CSS_BG, TAG_COLOR_CSS_TEXT }
