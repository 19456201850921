import { useTranslation } from 'gatsby-plugin-react-i18next'
import PropTypes from 'prop-types'
import React from 'react'
import Lottie from 'react-lottie'

import ChaosOff from '../../assets/chaos-off.svg'
import PlayIcon from '../../assets/icons/play.svg'
import headerAnimation from '../../assets/lotties/flagis'
import { Button } from '../common/button'
import {
  TwoColumn,
  TwoColumnAnimation,
  TwoColumnContent,
} from '../common/two-column'

const HeaderHome = ({ onVideoButtonClick }) => {
  const { t } = useTranslation('components', { keyPrefix: 'headerHome' })

  return (
    <header>
      <div className="container mx-auto">
        <h1 className="hidden">Flagis ON. Chaos OFF.</h1>
        <TwoColumn>
          <TwoColumnContent className="self-center">
            <ChaosOff className="md:mt-10 2xl:mt-12 mb-10" />
            <p className="sm:w-8/12 md:w-10/12 lg:w-auto xl:w-11/12 2xl:w-10/12 text-lg leading-6 xl:whitespace-pre-line">
              {t('text')}
            </p>
            <div className="flex my-4 lg:mt-4 lg:mb-16 2xl:mt-6 2xl:mb-20 space-x-5 z-40">
              <Button
                color="green"
                size="sm"
                title={t('getStarted')}
                to="https://app.flagis.com/sign-up"
              />
              <Button
                color="gray-200"
                icon={<PlayIcon className="mr-1 stroke-current" />}
                onClick={onVideoButtonClick}
                size="sm-light"
                title={t('playVideo')}
                target="_blank"
              />
            </div>
          </TwoColumnContent>
          <TwoColumnAnimation className="self-end">
            <Lottie options={{ animationData: headerAnimation }} />
          </TwoColumnAnimation>
        </TwoColumn>
      </div>
    </header>
  )
}

HeaderHome.propTypes = {
  onVideoButtonClick: PropTypes.func.isRequired,
}

export { HeaderHome }
