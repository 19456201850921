import PropTypes from 'prop-types'
import React from 'react'

const SectionFeaturesContentItem = ({
  animationElement,
  isActive,
  text,
  title,
}) => (
  <div
    className={`absolute w-full lg:h-98 flex flex-1 flex-col justify-between items-center ${
      isActive ? 'opacity-100' : 'opacity-0'
    }`}
  >
    <span className="block lg:hidden mt-14 mb-3 text-lg font-bold uppercase text-white">
      {title}
    </span>
    <p className="lg:w-auto xl:w-2/3 2xl:w-1/2 mb-12 text-lg text-white text-center lg:whitespace-pre-line">
      {text}
    </p>
    <div className="w-full lg:h-96">{animationElement}</div>
  </div>
)

SectionFeaturesContentItem.defaultProps = {
  isActive: false,
}

SectionFeaturesContentItem.propTypes = {
  animationElement: PropTypes.element.isRequired,
  isActive: PropTypes.bool,
  text: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export { SectionFeaturesContentItem }
