import { useTranslation } from 'gatsby-plugin-react-i18next'
import React from 'react'
import Lottie from 'react-lottie'

import { SectionFeaturesContentItem as ContentItem } from './section-features-content-item'
import { SectionFeaturesMenuItem as MenuItem } from './section-features-menu-item'

import AttachmentsIcon from '../../assets/icons/feature-attachments.svg'
import CalendarIcon from '../../assets/icons/feature-calendar.svg'
import ColorModesIcon from '../../assets/icons/feature-color-modes.svg'
import CommentsIcon from '../../assets/icons/feature-comments.svg'
import ReminderIcon from '../../assets/icons/feature-reminders.svg'
import TasksIcon from '../../assets/icons/feature-tasks.svg'
import attachmentsAnimation from '../../assets/lotties/attachments'
import calendarAnimation from '../../assets/lotties/calendar'
import colorModesAnimation from '../../assets/lotties/color-modes'
import commentsAnimation from '../../assets/lotties/comments'
import calendarAnimationCs from '../../assets/lotties/cs/calendar'
import notificationAnimationCs from '../../assets/lotties/cs/notification'
import notificationAnimation from '../../assets/lotties/notification'
import tasksNotesAnimation from '../../assets/lotties/tasks-notes'
import { useCurrentLanguage } from '../../hooks/useCurrentLanguage'
import { useSwitcher } from '../common/use-switcher'

const SectionFeatures = () => {
  const { t } = useTranslation('components', {
    keyPrefix: 'sectionFeatures',
  })
  const switcher = useSwitcher(5)
  const { isCs } = useCurrentLanguage()

  return (
    <section className="features bg-blue-gradient">
      <div className="container mx-auto">
        <div className="lg:flex pt-14 lg:pt-20 space-x-0 lg:space-x-10">
          <ul className="flex lg:flex-col items-center lg:items-stretch justify-center lg:justify-start space-x-2 lg:space-x-0 lg:space-y-3 pl-0 list-none">
            <MenuItem
              isActive={switcher.handleIsActive(0)}
              IconElement={TasksIcon}
              onClick={switcher.handleSwitch(0)}
              title={t('tasks.title')}
            />
            <MenuItem
              isActive={switcher.handleIsActive(1)}
              IconElement={CommentsIcon}
              onClick={switcher.handleSwitch(1)}
              title={t('comments.title')}
            />
            <MenuItem
              isActive={switcher.handleIsActive(2)}
              IconElement={AttachmentsIcon}
              onClick={switcher.handleSwitch(2)}
              title={t('attachments.title')}
            />
            <MenuItem
              isActive={switcher.handleIsActive(3)}
              IconElement={ReminderIcon}
              onClick={switcher.handleSwitch(3)}
              title={t('reminders.title')}
            />
            <MenuItem
              isActive={switcher.handleIsActive(4)}
              IconElement={ColorModesIcon}
              onClick={switcher.handleSwitch(4)}
              title={t('colorModes.title')}
            />
            <MenuItem
              isActive={switcher.handleIsActive(5)}
              IconElement={CalendarIcon}
              onClick={switcher.handleSwitch(5)}
              title={t('calendar.title')}
            />
          </ul>
          <div className="relative flex-1 h-[27rem] sm:h-[30rem] lg:h-98">
            <ContentItem
              animationElement={
                <Lottie options={{ animationData: tasksNotesAnimation }} />
              }
              isActive={switcher.handleIsActive(0)}
              text={t('tasks.text')}
              title={t('tasks.title')}
            />
            <ContentItem
              animationElement={
                <Lottie options={{ animationData: commentsAnimation }} />
              }
              isActive={switcher.handleIsActive(1)}
              text={t('comments.text')}
              title={t('comments.title')}
            />
            <ContentItem
              animationElement={
                <Lottie options={{ animationData: attachmentsAnimation }} />
              }
              isActive={switcher.handleIsActive(2)}
              text={t('attachments.text')}
              title={t('attachments.title')}
            />
            <ContentItem
              animationElement={
                <Lottie
                  options={{
                    animationData: isCs
                      ? notificationAnimationCs
                      : notificationAnimation,
                  }}
                />
              }
              isActive={switcher.handleIsActive(3)}
              text={t('reminders.text')}
              title={t('reminders.title')}
            />
            <ContentItem
              animationElement={
                <Lottie options={{ animationData: colorModesAnimation }} />
              }
              isActive={switcher.handleIsActive(4)}
              text={t('colorModes.text')}
              title={t('colorModes.title')}
            />
            <ContentItem
              animationElement={
                <Lottie
                  options={{
                    animationData: isCs
                      ? calendarAnimationCs
                      : calendarAnimation,
                  }}
                />
              }
              isActive={switcher.handleIsActive(5)}
              text={t('calendar.text')}
              title={t('calendar.title')}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export { SectionFeatures }
