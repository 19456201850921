import PropTypes from 'prop-types'
import React from 'react'

const SectionBenefitsBox = ({ Icon, text, title }) => (
  <div className="col-span-3 lg:col-span-1 flex flex-col py-8 lg:py-11 pr-8 pl-12 bg-gray-gradient rounded-3xl">
    <div className="flex items-center lg:block space-x-8 lg:space-x-0 pb-4">
      <Icon className="w-14 h-14 flex align-center" />
      <h3 className="lg:mt-4 font-title text-lg font-bold">{title}</h3>
    </div>
    <p className="mt-3">{text}</p>
  </div>
)

SectionBenefitsBox.propTypes = {
  Icon: PropTypes.any.isRequired,
  text: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export { SectionBenefitsBox }
