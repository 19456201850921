import { useTranslation } from 'gatsby-plugin-react-i18next'
import React from 'react'
import { isMobile } from 'react-device-detect'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

import { SectionReviewsArrows } from './section-reviews-arrows'
import { SectionReviewsItem } from './section-reviews-item'

import anetaIvanovaPhoto from '../../assets/reviews/aneta-ivanova.png'
import anetaIvanovaPhoto2x from '../../assets/reviews/aneta-ivanova@2x.png'
import filipBartekPhoto from '../../assets/reviews/filip-bartek.png'
import filipBartekPhoto2x from '../../assets/reviews/filip-bartek@2x.png'
import hanaTaflirovaPhoto from '../../assets/reviews/hana-taflirova.png'
import hanaTaflirovaPhoto2x from '../../assets/reviews/hana-taflirova@2x.png'
import ladislavNevrklaPhoto from '../../assets/reviews/ladislav-nevrkla.png'
import ladislavNevrklaPhoto2x from '../../assets/reviews/ladislav-nevrkla@2x.png'
import milanDufkaPhoto from '../../assets/reviews/milan-dufka.png'
import milanDufkaPhoto2x from '../../assets/reviews/milan-dufka@2x.png'
import vendyZitterbartPhoto from '../../assets/reviews/vendy-zitterbart.png'
import vendyZitterbartPhoto2x from '../../assets/reviews/vendy-zitterbart@2x.png'

const SectionReviews = () => {
  const { t } = useTranslation('components', { keyPrefix: 'sectionReviews' })

  const carouselSettings = {
    arrows: false,
    autoPlay: isMobile,
    autoPlaySpeed: 10000,
    shouldResetAutoplay: true,
    customButtonGroup: <SectionReviewsArrows />,
    renderButtonGroupOutside: true,
    draggable: false,
    infinite: true,
    keyBoardControl: false,
    responsive: {
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        slidesToSlide: 3,
      },
      tablet: {
        breakpoint: { max: 1024, min: 768 },
        items: 2,
        slidesToSlide: 2,
      },
      mobile: {
        breakpoint: { max: 767, min: 0 },
        items: 1,
        slidesToSlide: 1,
      },
    },
    ssr: true,
  }

  return (
    <section className="my-14 lg:my-24">
      <div className="container mx-auto relative px-0 sm:px-24 md:px-16 lg:px-24">
        <Carousel {...carouselSettings}>
          <SectionReviewsItem
            author={t('bartek.author')}
            photo={filipBartekPhoto}
            photo2x={filipBartekPhoto2x}
            profession={t('bartek.profession')}
            text={t('bartek.text')}
          />
          <SectionReviewsItem
            author={t('zitterbart.author')}
            photo={vendyZitterbartPhoto}
            photo2x={vendyZitterbartPhoto2x}
            profession={t('zitterbart.profession')}
            text={t('zitterbart.text')}
          />
          <SectionReviewsItem
            author={t('nevrkla.author')}
            photo={ladislavNevrklaPhoto}
            photo2x={ladislavNevrklaPhoto2x}
            profession={t('nevrkla.profession')}
            text={t('nevrkla.text')}
          />
          <SectionReviewsItem
            author={t('taflirova.author')}
            photo={hanaTaflirovaPhoto}
            photo2x={hanaTaflirovaPhoto2x}
            profession={t('taflirova.profession')}
            text={t('taflirova.text')}
          />
          <SectionReviewsItem
            author={t('dufka.author')}
            photo={milanDufkaPhoto}
            photo2x={milanDufkaPhoto2x}
            profession={t('dufka.profession')}
            text={t('dufka.text')}
          />
          <SectionReviewsItem
            author={t('ivanova.author')}
            photo={anetaIvanovaPhoto}
            photo2x={anetaIvanovaPhoto2x}
            profession={t('ivanova.profession')}
            text={t('ivanova.text')}
          />
        </Carousel>
      </div>
    </section>
  )
}

export { SectionReviews }
