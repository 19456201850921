import PropTypes from 'prop-types'
import React from 'react'

const SectionReviewsItem = ({ author, photo, photo2x, profession, text }) => (
  <div className="h-full bg-gray-gradient rounded-3xl mx-4 px-9 pt-10 pb-8">
    <div className="h-full flex flex-col justify-between">
      <p>{text}</p>
      <div className="grid grid-cols-12 items-center mt-5 -mr-3">
        <div className="col-span-8">
          <span className="block font-bold">{author}</span>
          <span className="block">{profession}</span>
        </div>
        <div className="col-span-4">
          <img
            className=""
            src={photo}
            srcSet={`${photo} 1x, ${photo2x} 2x`}
            alt={author}
          />
        </div>
      </div>
    </div>
  </div>
)

SectionReviewsItem.propTypes = {
  author: PropTypes.string.isRequired,
  photo: PropTypes.any.isRequired,
  photo2x: PropTypes.any.isRequired,
  profession: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}

export { SectionReviewsItem }
