import PropTypes from 'prop-types'
import React, { useMemo } from 'react'

import { TAG_COLOR_CSS_BG } from '../../config/tag-color'
import { computeIntHash } from '../../utils/common'

const FlagisDemoTaskTag = ({ colorIndex, value }) => {
  const preparedColorIndex = useMemo(
    () => colorIndex ?? computeIntHash(value, 23),
    [colorIndex, value],
  )

  return (
    <div
      className={`px-3 py-1 ${TAG_COLOR_CSS_BG[preparedColorIndex]} rounded-2xl text-sm text-white leading-none`}
    >
      {value}
    </div>
  )
}

FlagisDemoTaskTag.defaultProps = {
  colorIndex: null,
}

FlagisDemoTaskTag.propTypes = {
  colorIndex: PropTypes.number,
  value: PropTypes.string.isRequired,
}

export { FlagisDemoTaskTag }
