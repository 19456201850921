import PropTypes from 'prop-types'
import React, { useCallback, useMemo } from 'react'

import TagChildren from '../../assets/demo/tag-children.svg'
import Tag from '../../assets/demo/tag.svg'
import { TAG_COLOR_CSS_TEXT } from '../../config/tag-color'
import { computeIntHash } from '../../utils/common'

const FlagisDemoFilter = ({
  children,
  colorIndex,
  filters,
  image,
  onClick,
  onIsActive,
  type,
  value,
}) => {
  const preparedColorIndex = useMemo(
    () => colorIndex ?? computeIntHash(value, 23),
    [colorIndex, value],
  )

  const filterIds = useMemo(() => [...filters, value], [filters, value])
  const handleClick = useCallback(
    () => onClick(filterIds),
    [onClick, filterIds],
  )

  return (
    <>
      <button
        className={`w-full min-h-[30px] flex items-center px-[10px] py-[6px] my-[4px] rounded-2xl ${
          onIsActive(filterIds) ? 'bg-gray-600/40' : 'hover:bg-gray-600/20'
        }`}
        onClick={handleClick}
        type="button"
      >
        <div className="w-10 flex items-center">
          {type === 'tag' && children.length > 0 && (
            <TagChildren className={TAG_COLOR_CSS_TEXT[preparedColorIndex]} />
          )}
          {type === 'tag' && children.length === 0 && (
            <Tag className={TAG_COLOR_CSS_TEXT[preparedColorIndex]} />
          )}
          {type === 'user' && <img className="w-5" src={image} alt={value} />}
        </div>
        <span
          className={`leading-none ${
            onIsActive(filterIds) ? 'font-semibold' : ''
          }`}
        >
          {value}
        </span>
      </button>
      {children.length > 0 && (
        <div className="ml-7">
          {children.map(child => (
            <FlagisDemoFilter
              key={`${value}-${child.value}`}
              filters={filterIds}
              onClick={onClick}
              onIsActive={onIsActive}
              {...child}
            />
          ))}
        </div>
      )}
    </>
  )
}

FlagisDemoFilter.defaultProps = {
  children: [],
  colorIndex: null,
  filters: [],
  image: null,
}

FlagisDemoFilter.propTypes = {
  children: PropTypes.array,
  colorIndex: PropTypes.number,
  filters: PropTypes.array,
  image: PropTypes.any,
  onClick: PropTypes.func.isRequired,
  onIsActive: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['tag', 'user']).isRequired,
  value: PropTypes.string.isRequired,
}

export { FlagisDemoFilter }
