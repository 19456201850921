import { useTranslation } from 'gatsby-plugin-react-i18next'
import PropTypes from 'prop-types'
import React, { useCallback, useMemo, useState } from 'react'

import { FlagisDemoFilter } from './flagis-demo-filter'
import { FlagisDemoTask } from './flagis-demo-task'

import MouseClickIcon from '../../assets/icons/mouse-click.svg'

const FlagisDemo = ({ filters, tasks }) => {
  const { t } = useTranslation('components', { keyPrefix: 'flagisDemo' })

  const [actualFilters, setActualFilters] = useState([])
  const handleFilter = useCallback(
    newValue =>
      setActualFilters(actualValue =>
        JSON.stringify(actualValue) === JSON.stringify(newValue)
          ? []
          : newValue,
      ),
    [setActualFilters],
  )
  const handleIsActive = useCallback(
    filterIds =>
      JSON.stringify(filterIds) ===
      JSON.stringify(actualFilters.slice(0, filterIds.length)),
    [actualFilters],
  )

  const filteredTasks = useMemo(
    () =>
      tasks.filter(
        task =>
          actualFilters.filter(
            filter =>
              task.tags.find(tag => tag.value === filter) ||
              task.user?.value === filter,
          ).length === actualFilters.length,
      ),
    [actualFilters, tasks],
  )

  return (
    <div className="relative bg-gradient-to-r from-[#998DE9] to-[#6FB9F9] p-[10px] rounded-[60px]">
      <div className="h-[630px] grid grid-cols-12 bg-white rounded-[50px] py-8 pl-5 xl:pl-10 pr-10 xl:pr-14">
        <div className="col-span-3 pt-2 pb-2 pr-6">
          {filters.map(filter => (
            <FlagisDemoFilter
              key={`root-${filter.value}`}
              onClick={handleFilter}
              onIsActive={handleIsActive}
              {...filter}
            />
          ))}
        </div>
        <div className="col-span-9">
          {filteredTasks.map(task => (
            <FlagisDemoTask key={task.subject} {...task} />
          ))}
        </div>
      </div>
      <div className="absolute left-16 -bottom-3 bg-white rounded-[10px] drop-shadow-[0_0_13.5px_rgba(0,0,0,0.25)]">
        <div className="flex items-center px-4 py-2">
          <MouseClickIcon className="h-6" />
          <span className="pl-3 pr-1 text-[#2A2F31] leading-none">
            {t('explore')}
          </span>
        </div>
      </div>
    </div>
  )
}

FlagisDemo.propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      children: PropTypes.array,
      colorIndex: PropTypes.number,
      type: PropTypes.oneOf(['tag', 'user']).isRequired,
      userImage: PropTypes.any,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  tasks: PropTypes.arrayOf(
    PropTypes.shape({
      subject: PropTypes.string.isRequired,
      tags: PropTypes.arrayOf(
        PropTypes.shape({
          colorIndex: PropTypes.number,
          value: PropTypes.string.isRequired,
        }),
      ),
      user: PropTypes.shape({
        image: PropTypes.any.isRequired,
        value: PropTypes.string.isRequired,
      }),
    }),
  ).isRequired,
}

export { FlagisDemo }
