import { useTranslation } from 'gatsby-plugin-react-i18next'
import React from 'react'

import melindaImg from '../../assets/demo/users/melinda.png'
import ninaImg from '../../assets/demo/users/nina.png'
import simonImg from '../../assets/demo/users/simon.png'
import { FlagisDemo } from '../flagis-demo'

const SectionDemoManager = () => {
  const { t } = useTranslation('components', {
    keyPrefix: 'sectionDemo.data.manager',
  })

  const tags = {
    abcLtd: { colorIndex: 20, value: t('tags.abcLtd') },
    calls: { colorIndex: 7, value: t('tags.calls') },
    customers: { colorIndex: 18, value: t('tags.customers') },
    private: { colorIndex: 17, value: t('tags.private') },
    team: { colorIndex: 3, value: t('tags.team') },
    work: { colorIndex: 16, value: t('tags.work') },
    xyzLtd: { colorIndex: 10, value: t('tags.xyzLtd') },
    car: { colorIndex: 2, value: t('tags.car') },
    payments: { colorIndex: 12, value: t('tags.payments') },
    restaurants: { colorIndex: 11, value: t('tags.restaurants') },
  }

  const users = {
    melinda: { image: melindaImg, value: t('users.melinda') },
    nina: { image: ninaImg, value: t('users.nina') },
    simon: { image: simonImg, value: t('users.simon') },
  }

  return (
    <FlagisDemo
      filters={[
        { type: 'tag', ...tags.calls },
        {
          type: 'tag',
          ...tags.work,
          children: [
            { type: 'tag', ...tags.calls },
            {
              type: 'tag',
              ...tags.team,
              children: [
                { type: 'user', ...users.simon },
                { type: 'user', ...users.nina },
              ],
            },
            {
              type: 'tag',
              ...tags.customers,
              children: [
                { type: 'tag', ...tags.abcLtd },
                { type: 'tag', ...tags.xyzLtd },
              ],
            },
          ],
        },
        {
          type: 'tag',
          ...tags.private,
          children: [{ type: 'user', ...users.melinda }],
        },
        { type: 'tag', ...tags.payments },
        { type: 'tag', ...tags.car },
        { type: 'tag', ...tags.restaurants },
      ]}
      tasks={[
        {
          subject: t('tasks.1'),
          tags: [tags.calls, tags.work],
        },
        {
          subject: t('tasks.2'),
          tags: [tags.calls, tags.private],
        },
        {
          subject: t('tasks.3'),
          tags: [tags.calls, tags.work, tags.team],
        },
        {
          subject: t('tasks.4'),
          tags: [tags.calls, tags.work, tags.customers, tags.abcLtd],
        },
        {
          subject: t('tasks.5'),
          tags: [tags.work, tags.team],
        },
        {
          subject: t('tasks.6'),
          tags: [tags.work, tags.team],
          user: users.simon,
        },
        {
          subject: t('tasks.7'),
          tags: [tags.work, tags.team],
          user: users.nina,
        },
        {
          subject: t('tasks.8'),
          tags: [tags.work, tags.customers, tags.xyzLtd],
        },
        {
          subject: t('tasks.9'),
          tags: [tags.private],
          user: users.melinda,
        },
        { subject: t('tasks.10'), tags: [tags.car, tags.payments] },
        { subject: t('tasks.11'), tags: [tags.restaurants] },
      ]}
    />
  )
}

export { SectionDemoManager }
