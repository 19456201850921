import PropTypes from 'prop-types'
import React, { useCallback } from 'react'

import LeftIcon from '../../assets/icons/slider-left-arrow.svg'
import RightIcon from '../../assets/icons/slider-right-arrow.svg'

const SectionReviewsArrows = ({ next, previous }) => {
  const handleNext = useCallback(() => next(), [next])
  const handlePrevious = useCallback(() => previous(), [previous])

  return (
    <>
      <div className="hidden absolute top-0 left-0 sm:flex items-center h-full">
        <button className="ml-10" onClick={handlePrevious} type="button">
          <LeftIcon />
        </button>
      </div>
      <div className="hidden absolute top-0 right-0 sm:flex items-center h-full">
        <button className="mr-10" onClick={handleNext} type="button">
          <RightIcon />
        </button>
      </div>
    </>
  )
}

SectionReviewsArrows.propTypes = {
  next: PropTypes.func.isRequired,
  previous: PropTypes.func.isRequired,
}

export { SectionReviewsArrows }
