import React, { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'

import { VideoPlayer } from '../common/video-player'

const SectionVideo = forwardRef((props, ref) => {
  const {
    i18n: { language },
  } = useTranslation('components')

  const videoUrl =
    language === 'cs'
      ? 'https://youtu.be/Dxp00pshWW0'
      : 'https://youtu.be/xAB4t4zvRm0'

  return (
    <section className="pb-14 lg:pb-24" ref={ref}>
      <div className="container mx-auto">
        <div className="grid grid-cols-12">
          <div className="col-span-12 2xl:col-start-2 2xl:col-end-12">
            <VideoPlayer url={videoUrl} />
          </div>
        </div>
      </div>
    </section>
  )
})

export { SectionVideo }
