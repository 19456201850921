import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import React, { useCallback, useRef } from 'react'

import '../styles/global.css'
import { ContentMain } from '../components/common/content-main'
import { GetOnMobile } from '../components/common/get-on-mobile'
import { CookiesBox } from '../components/cookies-box'
import { Footer } from '../components/footer'
import { HeaderHome } from '../components/header-home'
import { Navigation } from '../components/navigation'
import { SectionAcceptTask } from '../components/section-accept-task'
import { SectionBenefits } from '../components/section-benefits'
import { SectionDemo } from '../components/section-demo'
import { SectionFeatures } from '../components/section-features'
import { SectionReviews } from '../components/section-reviews'
import { SectionSimplicity } from '../components/section-simplicity'
import { SectionSmartView } from '../components/section-smart-view'
import { SectionStores } from '../components/section-stores'
import { SectionVideo } from '../components/section-video'
import { SectionWhy } from '../components/section-why'
import { SEO } from '../components/seo'

const HomePage = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'homepage' })
  const videoRef = useRef(null)

  const handleScrollToVideo = useCallback(() => {
    videoRef.current.scrollIntoView()
  }, [videoRef])

  return (
    <>
      <SEO
        description={t('description')}
        isTitleTemplate={false}
        title={t('title')}
      />
      <div className="flex flex-col h-screen justify-between">
        <GetOnMobile />
        <Navigation />
        <ContentMain>
          <HeaderHome onVideoButtonClick={handleScrollToVideo} />
          <SectionSimplicity />
          <SectionSmartView />
          <SectionAcceptTask />
          <SectionFeatures />
          <SectionBenefits />
          <SectionVideo ref={videoRef} />
          <SectionWhy />
          <SectionReviews />
          <SectionDemo />
          <SectionStores />
        </ContentMain>
        <Footer />
      </div>
      <CookiesBox />
    </>
  )
}

export default HomePage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
