import { useTranslation } from 'gatsby-plugin-react-i18next'
import React from 'react'
import { isAndroid, isIOS } from 'react-device-detect'

import { Button } from './button'

import Icon from '../../assets/icon.svg'

const GetOnMobile = () => {
  const { t } = useTranslation('components', {
    keyPrefix: 'common.getOnMobile',
  })

  if (!isAndroid && !isIOS) {
    return null
  }

  return (
    <div className="bg-blue-gradient">
      <div className="container mx-auto flex justify-between items-center py-3.5">
        <div className="flex items-center">
          <Icon className="w-9 h-9" />
          {isAndroid && (
            <span className="pl-3 text-lg text-white">{t('android')}</span>
          )}
          {isIOS && <span className="pl-3 text-lg text-white">{t('ios')}</span>}
        </div>
        {isAndroid && (
          <Button
            color="white"
            size="sm"
            title="Get Free"
            to="https://play.google.com/store/apps/details?id=com.flagis.mobile"
          />
        )}
        {isIOS && (
          <Button
            color="white"
            size="sm"
            title="Get Free"
            to="https://apps.apple.com/us/app/flagis/id1245312971"
          />
        )}
      </div>
    </div>
  )
}

export { GetOnMobile }
