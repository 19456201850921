import { useTranslation } from 'gatsby-plugin-react-i18next'
import { useMemo } from 'react'

export const useCurrentLanguage = () => {
  const { i18n } = useTranslation()

  return useMemo(
    () => ({
      isCs: i18n.language === 'cs',
    }),
    [i18n.language],
  )
}
