import { useCallback, useState } from 'react'

const useSwitcher = () => {
  const [activeIndex, setActiveIndex] = useState(0)

  const handleIsActive = useCallback(
    index => activeIndex === index,
    [activeIndex],
  )

  const handleSwitch = useCallback(
    index => () => setActiveIndex(index),
    [setActiveIndex],
  )

  return { handleIsActive, handleSwitch }
}

export { useSwitcher }
