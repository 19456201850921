import PropTypes from 'prop-types'
import React from 'react'

const SectionFeaturesMenuItem = ({ isActive, IconElement, onClick, title }) => (
  <li className="pb-0">
    <button
      className={`w-full flex items-center pl-3 pr-3 lg:pr-7 py-3 space-x-3 rounded-lg ${
        isActive
          ? 'bg-white text-blue-400'
          : 'bg-blue-200 hover:bg-blue-400 text-white'
      }`}
      onClick={onClick}
      type="button"
    >
      <IconElement className="w-6 h-6 fill-current stroke-current" />
      <span
        className={`hidden lg:inline font-semibold leading-6 uppercase ${
          isActive ? 'text-black' : 'text-white'
        }`}
      >
        {title}
      </span>
    </button>
  </li>
)

SectionFeaturesMenuItem.defaultProps = {
  isActive: false,
}

SectionFeaturesMenuItem.propTypes = {
  IconElement: PropTypes.any.isRequired,
  isActive: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
}

export { SectionFeaturesMenuItem }
