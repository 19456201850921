import { useTranslation } from 'gatsby-plugin-react-i18next'
import React from 'react'

import { SectionBenefitsBox } from './section-benefits-box'

import Benefit1Icon from '../../assets/icons/benefit-1.svg'
import Benefit2Icon from '../../assets/icons/benefit-2.svg'
import Benefit3Icon from '../../assets/icons/benefit-3.svg'

const SectionBenefits = () => {
  const { t } = useTranslation('components', { keyPrefix: 'sectionBenefits' })

  return (
    <section className="py-14 lg:py-24">
      <div className="container mx-auto">
        <div className="grid grid-cols-3 lg:gap-x-7 gap-y-6 lg:gap-y-0">
          <SectionBenefitsBox
            Icon={Benefit1Icon}
            text={t('addTask.text')}
            title={t('addTask.title')}
          />
          <SectionBenefitsBox
            Icon={Benefit2Icon}
            text={t('cooperate.text')}
            title={t('cooperate.title')}
          />
          <SectionBenefitsBox
            Icon={Benefit3Icon}
            text={t('complete.text')}
            title={t('complete.title')}
          />
        </div>
      </div>
    </section>
  )
}

export { SectionBenefits }
