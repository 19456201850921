import PropTypes from 'prop-types'
import React from 'react'

import { FlagisDemoTaskTag } from './flagis-demo-task-tag'

import Completed from '../../assets/demo/completed.svg'

const FlagisDemoTask = ({ subject, tags, user }) => (
  <div className="h-[52px] flex justify-between items-center border-b-[1px] border-[#DCDCDC] last:border-b-0">
    <div className="flex items-center">
      <Completed />
      <span className="ml-3 leading-none">{subject}</span>
    </div>
    <div className="flex items-center space-x-[6px]">
      {tags.map(tag => (
        <FlagisDemoTaskTag key={`${subject}-${tag.value}`} {...tag} />
      ))}
      {user && <img className="w-10" src={user.image} alt={user.value} />}
    </div>
  </div>
)

FlagisDemoTask.defaultProps = {
  tags: [],
  user: null,
}

FlagisDemoTask.propTypes = {
  subject: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      colorIndex: PropTypes.number,
      value: PropTypes.string.isRequired,
    }),
  ),
  user: PropTypes.shape({
    image: PropTypes.any.isRequired,
    value: PropTypes.string.isRequired,
  }),
}

export { FlagisDemoTask }
