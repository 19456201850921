import { useTranslation } from 'gatsby-plugin-react-i18next'
import PropTypes from 'prop-types'
import React from 'react'

import appStoreButton from '../../assets/app-store-button.png'
import googlePlayButton from '../../assets/google-play-button.png'
import { Button } from '../common/button'
import { Link } from '../common/link'

const SectionStores = ({ className }) => {
  const { t } = useTranslation('components', { keyPrefix: 'sectionStores' })

  return (
    <section className={className}>
      <div className="container mx-auto">
        <div className="relative">
          <div className="hidden sm:block section-stores-rotate">
            <div className="section-stores-box h-full mx-4" />
          </div>
          <div className="relative section-stores-box flex flex-col lg:flex-row justify-between items-center px-6 lg:px-10 xl:px-14 py-8">
            <div className="font-bold text-lg xl:text-xl 2xl:text-2xl text-white">
              {t('line1')}
              <br className="hidden lg:block" />
              <span className="lg:hidden"> </span>
              {t('line2')}
            </div>
            <div className="flex flex-col sm:flex-row items-center mt-6 lg:mt-0 sm:space-x-4">
              <Button
                className="my-3 sm:my-0"
                color="green"
                size="md"
                title={t('signUp')}
                to="https://app.flagis.com/sign-up"
              />
              <Link
                className="my-3 sm:my-0"
                target="_blank"
                to="https://apps.apple.com/us/app/flagis/id1245312971"
              >
                <img src={appStoreButton} alt="App Store" />
              </Link>
              <Link
                className="my-3 sm:my-0"
                target="_blank"
                to="https://play.google.com/store/apps/details?id=com.flagis.mobile"
              >
                <img src={googlePlayButton} alt="Google play" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

SectionStores.defaultProps = {
  className: 'pt-0 pb-20 lg:pt-36 lg:pb-32',
}

SectionStores.propTypes = {
  className: PropTypes.string,
}

export { SectionStores }
