import PropTypes from 'prop-types'
import React from 'react'

// ------ WRAPPER ---------------------------------------------------------------------------------

const TwoColumn = ({ children, className }) => (
  <div
    className={`grid grid-cols-12 lg:gap-x-10 xl:gap-x-16 2xl:gap-x-20 ${className}`}
  >
    {children}
  </div>
)

TwoColumn.defaultProps = {
  className: '',
}

TwoColumn.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
}

// ------ ANIMATION -------------------------------------------------------------------------------

const TwoColumnAnimation = ({ children, className }) => (
  <div
    className={`col-span-12 lg:col-span-7 xl:col-span-7 mt-10 lg:mt-0 order-2 lg:order-none ${className}`}
  >
    {children}
  </div>
)

TwoColumnAnimation.defaultProps = {
  className: '',
}

TwoColumnAnimation.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
}

// ------ CONTENT ---------------------------------------------------------------------------------

const TwoColumnContent = ({ children, className }) => (
  <div
    className={`col-span-12 lg:col-span-5 xl:col-span-5 order-1 lg:order-none ${className}`}
  >
    {children}
  </div>
)

TwoColumnContent.defaultProps = {
  className: '',
}

TwoColumnContent.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
}

export { TwoColumn, TwoColumnAnimation, TwoColumnContent }
