import PropTypes from 'prop-types'
import React from 'react'

const SectionDemoListItem = ({ isActive, onClick, value }) => (
  <button
    className={`w-40 py-1 border-b-4 text-center ${
      isActive ? 'border-blue' : 'border-[#F1F1F1] hover:border-black/50'
    }`}
    onClick={onClick}
    type="button"
  >
    <span
      className={`text-xl ${
        isActive ? 'text-blue font-bold' : 'text-black/50'
      }`}
    >
      {value}
    </span>
  </button>
)

SectionDemoListItem.defaultProps = {
  isActive: false,
}

SectionDemoListItem.propTypes = {
  isActive: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
}

export { SectionDemoListItem }
